.content h3 {
  font-size: 24px;
}
.content p {
  color: #858789;
}
.content button {
  margin-top: 1rem;
  width: 100%;
}
.content input {
  margin: 1rem 0;
}
.modal {
  position: absolute;
  display: flex;
  width: 300px;
  background-color: #fff;
  border: 1px solid #C0C0C0;
  border-radius: 8px;
  padding: 1rem;
  box-sizing: content-box;
  box-shadow: 0 0 1rem rgba(0,0,0,0.3);
}
@media screen and (min-width: 420px) {
  .modal {
    min-width: 350px;
    max-width: 490px;
  }
}
.modal input {
  width: 100%;
  padding: 1rem;
}
.overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
background-color: rgba(0,0,0,0.1)
}
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: none;
  background-color: transparent;
  color:#C4C4C4;
  cursor: pointer
}
.loginLinkButton {
  width: 100%;
  margin: .5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 5px;
}